import React, { useState ,useEffect} from "react";
import videopath from "../ASSETS/video.mp4";
import logopath from "../ASSETS/logo.png";
import logopaths from "../ASSETS/logos.png";
import { FaAlignJustify, FaTimes } from "react-icons/fa";


export default function MainHeader() {

    const [activeItem, setActiveItem] = useState(0);
    const handleClick = (index) => {
        setActiveItem(index);
    };


    const [manuBg,setManuBg] = useState("rgba(10, 9, 9, 0.438)");
    const [logo,setlogo] = useState({
        logo: logopath,
        size:25
    });
    
    const handleScroll = () => {
        const position = window.pageYOffset;
        console.log(position);
        if(position>50){
            setManuBg("rgb(175, 122, 15)")
            setlogo({
                logo: logopaths,
                size:38
            })
        }else{
            setManuBg("rgba(10, 9, 9, 0.438)")
            setlogo({
                logo: logopath,
                size:25
            })
        }
    };

  
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

 

    // ---------------------
    const [showBottomMenu, setshowBottomMenu] = useState("none");

    return (
        <div className="main-header">
            <video loop={true} autoPlay={true} muted className="bg-video">
                <source src={videopath} type="video/mp4" />
            </video>

            {/* MENU PHONE */}
            <div className="menu-phone">
                <div className="item-one" style={{background:manuBg}}>
                    <img className="logo one animated-box "  src={logo.logo} height={logo.size} />
                    <div className="one" onClick={()=>showBottomMenu=="none"?setshowBottomMenu("flex"):setshowBottomMenu("none")}>
                          {showBottomMenu=="flex"?<FaTimes className="icon" />:<FaAlignJustify className="icon" />}
                    </div>
                </div>
                <div className="item-two" style={{display:showBottomMenu}}>
                    <div className="item btn-acceuil" onClick={()=>setshowBottomMenu("none")} >ACCUEIL</div>
                    <div className="item btn-services" onClick={()=>setshowBottomMenu("none")} >SERVICES</div>
                    <div className="item btn-products" onClick={()=>setshowBottomMenu("none")} >PRODUITS</div>
                    <div className="item btn-partenaire" onClick={()=>setshowBottomMenu("none")} >PARTENAIRES</div>
                    <div className="item btn-contacts" onClick={()=>setshowBottomMenu("none")} >CONTACTS</div>
                    <div className="item btn-apropos" onClick={()=>setshowBottomMenu("none")} >APROPOS</div>
                </div>
            </div>



            {/* MENU DESKTOP */}
            <div className="menu-desktop" style={{background:manuBg}}>
                <img className="logo animated-box " src={logo.logo}  height={logo.size} />
                <div className="menu-all"> 
                    <div className={activeItem === 0 ? "item active btn-acceuil" : "item btn-acceuil"} onClick={() => handleClick(0)}>ACCUEIL</div>
                    <div className={activeItem === 1 ? "item active  btn-services" : "item btn-services"} onClick={() => handleClick(1)}>SERVICES</div>
                    <div className={activeItem === 2 ? "item active btn-products" : "item btn-products"} onClick={() => handleClick(2)}>PRODUITS</div>
                    <div className={activeItem === 3 ? "item active btn-partenaire" : "item btn-partenaire"} onClick={() => handleClick(3)}>PARTENAIRES</div>
                    <div className={activeItem === 4 ? "item active btn-contacts" : "item btn-contacts"} onClick={() => handleClick(4)}>CONTACTS</div>
                    <div className={activeItem === 5 ? "item active btn-apropos" : "item btn-apropos"} onClick={() => handleClick(5)}>APROPOS</div>
                </div>
            </div>

            <div className="content-menu">
                 <div className="title-one" data-aos="fade-right" data-aos-duration="3000">Votre Satisfaction</div>
                 <div className="title-two" >Est notre Priorité</div>
                 <div className="title-three"  data-aos="fade-left" data-aos-duration="3000">
                 La meilleure sécurité automobile par dispositif GPS, c’est nous ! Protégez votre véhicule avec notre technologie avancée. Suivi en temps réel, alertes instantanées et tranquillité d'esprit assurée.
                 </div>
                 <div className="btn-four">CONTACTEZ-NOUS</div>
            </div>

        </div>
    );
}
