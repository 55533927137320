import React, { useEffect, useState } from 'react';
import TK26 from '../ASSETS/PRO/pro-tk26.png'
import TK08 from '../ASSETS/PRO/pro-tk08.png'
import S3 from '../ASSETS/PRO/pro-3s.png'
import AT4 from '../ASSETS/PRO/pro-at4.png'
import TY5 from '../ASSETS/PRO/pro-ty5.png'
import SG20 from '../ASSETS/PRO/pro-sg20.png'
import TK21 from '../ASSETS/PRO/pro-tk21.png'
import QBIT from '../ASSETS/PRO/pro-qbit.png'
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';


function ProductsGrid() {
    const products = [
        { name: 'G08', price: 100, description: 'A great product.', imagepath: TK08 },
        { name: 'X3', price: 300, description: 'The best product.', imagepath: S3 },
        { name: 'FUEL SENSE', price: 300, description: 'The best product.', imagepath: QBIT },  
    ];
    const productsALl = [
        { name: 'G08', price: 100, description: 'A great product.', imagepath: TK08 },
        // { name: 'TK26', price: 200, description: 'An even greater product.', imagepath: TK26 },
        { name: 'X3', price: 300, description: 'The best product.', imagepath: S3 },
        // { name: 'ATK', price: 300, description: 'The best product.', imagepath: AT4 },
        { name: 'TZ8', price: 300, description: 'The best product.', imagepath: TY5 },
        { name: 'SG20', price: 300, description: 'The best product.', imagepath: SG20 },
        // { name: 'TK21', price: 300, description: 'The best product.', imagepath: TK21 },
        { name: 'FUEL SENSE', price: 300, description: 'The best product.', imagepath: QBIT },     // Add more products as needed
    ];

    const [all, setAll] = useState(false)
    useEffect(() => { }, [all])
    const [detail, showDetails] = useState(true)

    return (
        <div className="products-all" id='products-all'>
            <h2>NOS PRODUITS GPS</h2>
            <div className='separatorx' style={{ width: "100px" }}></div>
            <p data-aos="fade-left" data-aos-duration="3000">
                Nous proposons une gamme complète de traceurs GPS conçus pour répondre aux besoins variés de nos clients. Que ce soit pour la surveillance de véhicules, la gestion d'actifs ou la sécurité des personnes, nos traceurs GPS allient performance, discrétion et simplicité d'utilisation.
            </p>
            <div className="products-grid">
                {all == false ? products.map((product, index) => (
                    <div onClick={() => showDetails(true)} className="product " key={index} style={{ backgroundImage: `url("${product.imagepath}")` }}>
                        <div className='content'>
                            <div className='name'>{product.name}</div>
                        </div>
                    </div>
                )) : productsALl.map((product, index) => (
                    <div className="product " key={index} style={{ backgroundImage: `url("${product.imagepath}")` }}>
                        <div className='content'>
                            <div className='name'>{product.name}</div>
                        </div>
                    </div>
                ))}
            </div>
            <div className="btn-goto" onClick={() => setAll(!all)}>
                {all == false ? <IoIosArrowForward /> : <IoIosArrowBack />}
                <label htmlFor="">{all == false ? "VOIR TOUT" : "REDUIRE"} </label>
            </div>
        </div>
    );
}

export default ProductsGrid;
