import React from 'react';
import imgSecurity from '../ASSETS/security.png'

function Intro() {
    return (
        <div className="intro-container" id='intro-container'>
            <div className="intro-text">
                <h2>QUI SOMMES-NOUS</h2>
                <div className='separatorx' style={{ width: "100px" }}></div>
                <p data-aos="fade-right" data-aos-duration="3000">  <strong>SECAUTO SARL</strong> , Nous sommes spécialisés dans la sécurité automobile, offrant des solutions innovantes pour protéger votre véhicule. Notre expertise s’étend également à la vente de systèmes de sécurité de pointe, garantissant une tranquillité d'esprit pour nos clients. En parallèle, nous développons des logiciels sur mesure pour répondre aux besoins spécifiques du secteur automobile. Notre équipe d'experts passionnés s'engage à fournir des services de qualité, alliant technologie avancée et support personnalisé. Choisissez <strong>SECAUTO SARL</strong> pour une sécurité optimale et un service inégalé</p>
            </div>
            <div className="intro-image" data-aos="zoom-in" data-aos-duration="3000">
                <img src={imgSecurity} alt="Image de sécurité automobile" />
            </div>
        </div>
    );
}

export default Intro;