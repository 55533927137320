import React, { useState } from "react";
import logo from '../ASSETS/logos.png';
import { FaFacebook, FaInstagram, FaLinkedin, FaTiktok, FaTwitter } from "react-icons/fa";

export default function Footer() {
    var year = new Date();
    return <div className="footer-all">
        <div className="footer-one">
            <div className="one">
                <img src={logo} height={45} />
                <div className="text">Nous sommes votre partenaire de prédilection dans le domaine de la sécurité<br />  automobile,
                 spécialisé dans la conception et la mise en œuvre de <br />solutions de géolocalisation à la pointe de la technologie.</div>
            </div>
            <div className="footer-menu">
                <div className="item btn-acceuil">ACCUEIL</div>
                <div className="item btn-services">SERVICES</div>
                <div className="item btn-products">PRODUITS</div>
                <div className="item btn-contacts">CONTACTS</div>
            </div>
        </div>
        <div className="footer-two">
            <div className="social">
                <FaFacebook className="icon" onClick={() => window.open("https://www.facebook.com/share/FeLF7JRHtTHTSvc6/?mibextid=qi2Omg", '_blank')} />
                <FaLinkedin className="icon" onClick={() => window.open("https://www.linkedin.com/", '_blank')} />
                <FaInstagram className="icon" onClick={() => window.open("https://www.instagram.com/secauto_sarl?igsh=ZDM1NW81dTlicDdx", '_blank')} />
                <FaTiktok className="icon" onClick={() => window.open("https://vm.tiktok.com/ZMhFXRaMM/", '_blank')} />
            </div>
            <div className="copy">Copyright © {year.getFullYear()} <strong className="company">SECAUTO SARL</strong>™ . Tous droits réservés.</div>
        </div>
    </div>
}