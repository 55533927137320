import React, { useEffect } from "react";
import MainHeader from "./COMPONENTS/header"
import MainService from "./COMPONENTS/services"
import Intro from "./COMPONENTS/intro"
import Sofware from "./COMPONENTS/software"
import Products from "./COMPONENTS/products"
import Tech from "./COMPONENTS/tech"
import Footer from "./COMPONENTS/footer"
import ContactInfo from "./COMPONENTS/contacts"
import Testimony from "./COMPONENTS/testimony"
import '../src/STYLE/style.css'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { WhatsAppWidget } from 'react-whatsapp-widget';
import 'react-whatsapp-widget/dist/index.css';



function App() {
  useEffect(() => {
    AOS.init();
  }, [])
  return <div className="mapMutations">
    <MainHeader />
    <Intro />
    <MainService />
    <Sofware />
    <Products />
    <Tech />
    <ContactInfo/>
    <Testimony/>
    <Footer/>
    <WhatsAppWidget companyName={'SECAUTO SARL'} inputPlaceHolder={"Votre message"} sendButtonText={'Envoyer'} message={'Bonjour,\n\en quoi on peut vous aider ?'} phoneNumber="+243824642979" />
  </div>
}

export default App;
