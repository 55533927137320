import React from 'react';
import { FaMapMarkerAlt, FaEnvelope, FaPhoneAlt } from 'react-icons/fa';

const ContactInfo = () => {
    return (
        <div className='contacts' id='contacts'>
            <h2>NOS CONTACTS</h2>
            <div className='separatorx' style={{ width: "100px" }}></div>
            <p>Vous pouvez utiliser les coordonnées ci-dessous pour nous joindre et discuter d'avantage avec nous de nos produits et services, nous sommes prêts à vous recevoir et nos portes sont ouvertes, avec du personnel prêt pour les bonnes orientations.</p>
            <div className="contact-info">
                <div className="contact-item">
                <FaMapMarkerAlt style={{color:"rgb(175, 122, 15)",fontSize:"40px"}}/>
                    <h4>Equateur, Bvd. du 30 Juin</h4>
                </div>
                <div className="contact-item">
                <FaPhoneAlt style={{color:"rgb(175, 122, 15)",fontSize:"40px"}}/>
                    <h4>+243 824642979</h4>
                </div>
                <div className="contact-item">
                    <FaEnvelope style={{color:"rgb(175, 122, 15)",fontSize:"40px"}}/>
                    <h4>contacts@secauto.com</h4>
                </div>
            </div>
        </div>
    );
};

export default ContactInfo;
