import React from "react";
import img from '../ASSETS/testmony.jpg'
import { BsRulers, BsStar, BsStarFill } from 'react-icons/bs'
import unique from '../ASSETS/bruno.jpg'
import andy from '../ASSETS/newtech.png'

export default function Testimony() {
    return <div className="testimonies" id="testimonies"> 

        <img src={img} alt="" />
       
 
        <div className="testimony">
            <span className="stars">
                <BsStarFill className="icon" />
                <BsStarFill className="icon" />
                <BsStarFill className="icon" />
                <BsStarFill className="icon" />
                <BsStar className="icon" />
            </span>
            <p>Grâce à SECAUTO, notre flotte est désormais gérée avec efficacité et sécurité. Une collaboration fiable pour nos projets technologiques.</p>
            <span className="customer">
                <span className="avatar" style={{backgroundImage:`url(${andy})`}}></span>
                <span className="id">
                    <span className="name">CONGO NEW TECH</span>
                    <span className="title">Partenaire</span>
                </span>
            </span>
        </div>
        <div className="testimony">
            <span className="stars">
                <BsStarFill className="icon" />
                <BsStarFill className="icon" />
                <BsStarFill className="icon" />
                <BsStar className="icon" />
                <BsStar className="icon" />
            </span>
            
            <p>Nous exprimons notre entière satisfaction des services et de la collaboration avec SECAUTO, tout en mettant l'accent sur l'efficacité et la fiabilité dans les affaires.</p>
            <span className="customer">
                <span className="avatar " style={{backgroundImage:`url(${unique})`}}></span>
                <span className="id">
                    <span className="name">GTAP SARL</span>
                    <span className="title">Partenaire (Bruno le maire)</span>
                </span>
            </span>
        </div>
        <div className="text">
            <label className="title">TEMOIGNAGES</label>
            <span className='separatorx' style={{ width: "100px" }}></span>
            <p>Ces témoignages définissent la confiance que nos clients et partenaires ont envers nous et nos services.. nous priorisons la grandeur de nos collaborations envie de créer un monde merveilleux dans la technologie, joignez-vous à nous pour bâtir un monde positif.</p>
            <span className="btn-goto btn-contacts">JOIGNEZ-NOUS</span>
        </div>
    </div>
}