import React from 'react';
import imgSecurity from '../ASSETS/tech.png'
import { IoIosArrowForward } from 'react-icons/io';


function Tech() {
    return (
        <div className="tech-container">
             <div className="tech-image" data-aos="zoom-in" data-aos-duration="3000">
                <img src={imgSecurity} alt="Image de sécurité automobile" />
            </div>
            <div className="tech-text tech-text">
                <h2>APPRENEZ PLUS</h2>
                <div className='separatorx' style={{width:"100px"}}></div>
                <p><strong>SECAUTO SARL</strong>  est votre partenaire privilégié en matière de sécurité automobile, spécialisé dans les solutions avancées de géolocalisation. Protégez vos véhicules grâce à nos systèmes innovants, conçus pour offrir une tranquillité d'esprit et un contrôle total à chaque trajet. Grâce à notre technologie de pointe, suivez la position de votre flotte en temps réel et optimisez vos itinéraires. Faites confiance à <strong>SECAUTO SARL</strong> pour une surveillance continue et efficace, réduisant les risques de vol tout en augmentant la productivité de vos opérations.</p>
                     <div className="btn-goto-reverse" onClick={() => window.open("https://www.tiktok.com/@secauto/video/7416044197871979782?lang=en&q=secauto&t=1726911514513", '_blank')}>
                    <IoIosArrowForward />
                    APPRENDRE PLUS
                </div>
            </div>
          
        </div>
    );
}

export default Tech;