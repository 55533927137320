import React from 'react';
import { IoIosArrowForward } from 'react-icons/io';
import soft from '../ASSETS/softbg.png'

function Sofware() {
    return (
        <div className="intro-container software ">
            <div className="intro-image" data-aos="zoom-in" data-aos-duration="3000">
                <img src={soft} alt="Image de sécurité automobile" />
            </div>
            <div className="intro-text">
                <h2>NOTRE SYSTÈME DE GEOLOCALISATION</h2>
                <div className='separatorx' style={{ width: "100px" }}></div>
                <p data-aos="fade-left" data-aos-duration="3000">
                Nous mettons deux systèmes authentiques de géolocalisation de vos véhicules à votre disposition (TRACKSOLID PRO) pour vous permettre de localiser en temps réel , une version web et mobile.. avec des fonctionnalités extraordinaires comme , la géolocalisation, la gestion de clotures, les alertes , gestions de carburants,...avec un abonnement moins cher vous pouvez avec vos engisn dans vos poches.
                </p>
                <div className="btn-goto" onClick={() => window.open("https://www.tracksolidpro.com/resource/dev/index.html", '_blank')} >
                    <IoIosArrowForward />
                    VISITER NOTRE SYSTEME
                </div>
            </div>
        </div>
    );
}

export default Sofware;