import React from 'react';
import {  BsGeoAlt,BsCursor, BsLightbulb, BsCarFront } from 'react-icons/bs'

const services = [
    { id: 2, icon: <BsCursor />, title: "Vente de Traceurs GPS", content: "Nous proposons une gamme complète de traceurs GPS conçus pour répondre aux besoins variés de nos clients. Que ce soit pour la surveillance de véhicules, la gestion d'actifs ou la sécurité des personnes, nos traceurs GPS allient performance, discrétion et simplicité d'utilisation."  },
    { id: 3, icon: <BsLightbulb />, title: "Développement de Logiciels", content: "Nous développons des logiciels sur mesure pour optimiser et automatiser les processus d'affaires de nos clients. Notre expertise englobe les solutions personnalisées pour divers secteurs, incluant la programmation de systèmes de gestion de bases de données, d'applications mobiles et de logiciels de gestion intégrée."},
    { id: 1, icon: <BsGeoAlt />, title: "Services de Géolocalisation", content: "Les services de géolocalisation permettent de suivre en temps réel la position géographique de personnes, véhicules ou objets. Grâce à des technologies avancées, ces services offrent une précision et une fiabilité essentielles pour la gestion de flottes, la sécurité personnelle et la logistique." },
    { id: 3, icon: <BsCarFront />, title: "Vente Automobile", content: "Nous simplifions l’achat d’automobiles à travers toute la République Démocratique du Congo. Notre service s’adapte à vos besoins, et les prix varient en fonction du lieu de livraison, garantissant ainsi une expérience d'achat personnalisée et accessible. Découvrez nos options dès aujourd'hui."}
];

const MainService = () => (
    <div className="main-service" id="main-service">
        {services.map(service => (
            <div className="item water" key={service.id} >
                <div className="icon">{service.icon}</div>
                <div className="title">{service.title}</div>
                <div className='separatorx' style={{margin:"7px"}}></div>
                <div className="content">{service.content}</div>
            </div>
        ))}
    </div>
);

export default MainService;
